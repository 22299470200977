<template>
  <div class="min-h-screen flex items-center justify-centre">
    <div
      class="animate-spin rounded-full h-32 w-32 border-b-2 border-nav_color mx-auto"
    ></div>
  </div>
</template>

<script setup lang="ts">
const { signInHandler } = useSocialLogin()

onMounted(() => signInHandler())
</script>
